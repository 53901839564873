export const CHANGE_TO_HU = 'CHANGE_TO_HU';
export const CHANGE_TO_EN = 'CHANGE_TO_EN';

export const OPEN_SIDE_BAR = 'OPEN_SIDE_BAR';
export const CLOSE_SIDE_BAR = 'CLOSE_SIDE_BAR';

export const SHOW_EMAIL = 'SHOW_EMAIL';
export const HIDE_EMAIL = 'HIDE_EMAIL';

export const SHOW_THERAPIST = 'SHOW_THERAPIST';
export const HIDE_THERAPIST = 'HIDE_THERAPIST';

export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';

export const OPEN_SNACKBAR_SUCCESS = 'OPEN_SNACKBAR_SUCCESS';
export const OPEN_SNACKBAR_ERROR = 'OPEN_SNACKBAR_ERROR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const GET_SESSION_DATES = 'GET_SESSION_DATES';
export const GET_SESSION_DATES_SUCCESS = 'GET_SESSION_DATES_SUCCESS';
export const GET_SESSION_DATES_FAIL = 'GET_SESSION_DATES_FAIL';

export const SET_HEADER = 'SET_HEADER';
export const UNSET_HEADER = 'UNSET_HEADER';

export const OPEN_GALLERY = 'OPEN_GALLERY';
export const CLOSE_GALLERY = 'CLOSE_GALLERY';

export const HIDE_BUGREPORT = "HIDE_BUGREPORT";
export const SHOW_BUGREPORT = "SHOW_BUGREPORT";